import { useFetchFriendsQuery } from '../../store';

import { MemberCard } from '../../../widgets/ui/MemberCard';
import { Heading } from '../../../widgets/ui/Heading';

import { ReferalView } from '../ReferalView';

import s from './LeaderBoardFriendsView.module.css';

export const LeaderBoardFriendsView = () => {
  const { data: friends } = useFetchFriendsQuery();
  const friendsList = friends?.data || [];

  if (!friendsList.length) {
    return <ReferalView />;
  }

  return (
    <section className={s.outer}>
      <Heading>Most active</Heading>

      {friendsList.map((leader, index) => (
        <MemberCard
          key={leader.id}
          firstName={leader.first_name}
          lastName={leader.last_name}
          balancePoints={leader.balance_points}
          position={index + 1}
        />
      ))}
    </section>
  );
};
