import s from './Button.module.css';

export const Button = ({ className, children, disabled, onClick }) => {
  return (
    <div
      className={`${s.outer} ${disabled ? s.disabled : ''} ${className || ''}`}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </div>
  );
};
