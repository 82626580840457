import { BASE_URL } from '../../../../shared/config';

import s from './SquadPicture.module.css';

export const SquadPicture = ({ value, onChange }) => {
  let url = null;
  if (value instanceof File) {
    url = URL.createObjectURL(value);
  } else if (typeof value === 'string') {
    url = BASE_URL + value;
  }

  return (
    <section className={s.outer}>
      <div className={s.inner}>
        {url ? (
          <img className={s.image} src={url} alt="" />
        ) : (
          <label className={s.label}>
            <span>Add a logo</span>

            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                onChange(e.target.files[0] || null);
              }}
            />
          </label>
        )}
      </div>
    </section>
  );
};
