import { useEffect, useState } from 'react';

import { useSquadViewProvider } from '../../../shared/contexts/squad-view-context';
import { useAppProvider } from '../../../shared/contexts/app-context';
import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';
import { BASE_URL, BOT_USERNAME } from '../../../shared/config';
import { CopyIcon } from '../../../shared/icons/CopyIcon';
import { Button } from '../../../shared/ui/Button';
import { formatScore } from '../../../shared/helpers/formatters';
import { openTelegramLink } from '../../../shared/helpers/telegram';

import {
  useJoinToSquadMutation,
  useLeaveJoinedSquadMutation,
  useFetchMostActiveSquadMembersQuery,
} from '../../../app/store';

import { Heading } from '../../../widgets/ui/Heading';
import { MemberCard } from '../../../widgets/ui/MemberCard';

import s from './SquadView.module.css';

export const SquadView = ({ squad, visible }) => {
  const { open, close } = useSquadViewProvider();
  const {
    squad: mySquad,
    joinedSquad,
    isLoading: isProfileLoading,
  } = useAppProvider();
  const { setRoute } = useRouterProvider();
  const [joinToSquad, { isLoading }] = useJoinToSquadMutation();
  const [leaveJoinedSquad, { isLoading: isLeaveJoinedSquadLoading }] =
    useLeaveJoinedSquadMutation();
  const { data: mostActiveMembersData, refetch } =
    useFetchMostActiveSquadMembersQuery(squad?.id, {
      skip: !squad,
    });

  const [clientHeight, setClientHeight] = useState();
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    const handler = () => {
      setClientHeight(document.documentElement.clientHeight);
      setHeaderHeight(document.body.querySelector('header')?.offsetHeight);
    };
    window.addEventListener('resize', handler);
    handler();
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      setHeaderHeight(document.body.querySelector('header')?.offsetHeight);
    }
  }, [visible]);

  const mostActiveMembers = mostActiveMembersData?.data || [];
  const canDisplay = !!visible && !!squad;
  const refLink = `https://t.me/${BOT_USERNAME}?start=${encodeURI(
    squad?.ref_link
  )}`;

  return (
    <section
      className={`${s.outer} ${canDisplay ? s.visible : ''}`}
      onClick={(e) => {
        if (e.target.classList.contains(s.outer)) {
          close();
        }
      }}
      style={
        clientHeight && headerHeight
          ? {
              '--client-height': `${clientHeight}px`,
              '--header-height': `${headerHeight}px`,
            }
          : {}
      }
    >
      <div className={s.inner}>
        <a
          className={s.imageOuter}
          href={squad?.soc_link}
          target="_blank"
          rel="noreferrer"
        >
          {!!squad && <img src={BASE_URL + squad.file_path} alt="" />}
        </a>

        <div className={s.nameOuter}>
          <a href={squad?.soc_link} target="_blank" rel="noreferrer">
            {squad?.name}
          </a>
        </div>

        <div className={s.socOuter}>
          <span
            onClick={() => {
              openTelegramLink(
                `https://t.me/share/url?text=${encodeURI(
                  'Переходи по ссылке, вступай в сквад и получай ускоренную добычу ресурсов, чтобы зарабатывать быстрее остальных'
                )}&url=${encodeURIComponent(refLink)}`
              );
            }}
          >
            Referral link
          </span>

          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(refLink);
            }}
          />
        </div>

        {!!squad && !!mySquad && squad.id === mySquad.id && (
          <Button
            className={s.btn}
            onClick={() => {
              close();
              setRoute(ROUTES.LEADERBOARD_SQUAD_EDIT);
            }}
          >
            Edit
          </Button>
        )}

        {!!squad && !mySquad && !joinedSquad && (
          <Button
            className={s.btn}
            disabled={isLoading || isProfileLoading}
            onClick={() => {
              if (squad) {
                joinToSquad(squad.id).then((res) => {
                  if (res?.data?.success && res?.data?.squad) {
                    open(res.data.squad);
                  }
                });
              }
            }}
          >
            Join
          </Button>
        )}

        {!!squad && !!joinedSquad && squad.id === joinedSquad.id && (
          <Button
            className={s.btn}
            disabled={isLeaveJoinedSquadLoading}
            onClick={() => {
              if (squad) {
                leaveJoinedSquad().then((res) => {
                  if (res?.data?.success && res?.data?.squad) {
                    open(res.data.squad);
                    refetch();
                  }
                });
              }
            }}
          >
            Leave
          </Button>
        )}

        <div className={s.infoBlocks}>
          <div className={s.infoBlock}>
            <div>Rank</div>

            <div>{squad?.rank || 0}</div>
          </div>

          <div className={s.infoBlock}>
            <div>Points</div>

            <div>{formatScore(squad?.balance_points || 0)}</div>
          </div>

          <div className={s.infoBlock}>
            <div>Members</div>

            <div>{squad?.total_members || 0}</div>
          </div>
        </div>

        {mostActiveMembers.length > 0 && (
          <>
            <Heading className={s.heading}>Most active</Heading>

            {mostActiveMembers.map((member, index) => (
              <MemberCard
                key={member.id}
                firstName={member.first_name}
                lastName={member.last_name}
                balancePoints={member.balance_points}
                position={index + 1}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
};
