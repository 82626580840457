import { GoldDrillIcon } from '../../../../shared/icons/GoldDrillIcon';

import s from './EarnBlock.module.css';

export const EarnBlock = ({
  title,
  variant, // 'orange' | 'green'
  amount,
  firstWeekFactor,
  squadFactor,
}) => {
  const newAmount = `${Number(amount) || 0}`.padStart(12, '0');
  const firstNonZeroIndex = Array.from(newAmount).findIndex((n) => n > 0);
  let newConstructedAmount = '';
  let newConstructedAmountHidden = '';

  for (let i = newAmount.length - 1; i >= 0; --i) {
    const char = newAmount[i];
    const suffix = i % 3 === 0 ? ' ' : '';

    if (firstNonZeroIndex > 0 && i < firstNonZeroIndex) {
      newConstructedAmountHidden = suffix + char + newConstructedAmountHidden;
    } else {
      newConstructedAmount = suffix + char + newConstructedAmount;
    }
  }

  return (
    <div className={`${s.block} ${s[`variant-${variant}`]}`}>
      <div className={s.title}>{title}</div>

      <div className={s.row}>
        <GoldDrillIcon className={s.icon} />

        <div className={s.amount}>
          {!!newConstructedAmountHidden.length && (
            <span>{newConstructedAmountHidden}</span>
          )}
          {newConstructedAmount}
        </div>
      </div>

      {(!!firstWeekFactor || !!squadFactor) && (
        <div className={s.boostFactors}>
          {!!squadFactor && (
            <div className={s.boostFactorsSquad}>
              <div className={s.boostFactorsSquadLabel}>squad</div>
              <div className={s.boostFactorsSquadValue}>x{squadFactor}</div>
            </div>
          )}
          {!!firstWeekFactor && (
            <div className={s.boostFactorsWeek}>
              <div className={s.boostFactorsWeekLabel}>1st week</div>
              <div className={s.boostFactorsWeekValue}>x{firstWeekFactor}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
