import { createContext, useContext, useMemo, useState } from 'react';

export const ROUTES = {
  EARN: 'EARN',
  QUESTS_ACTIVE: 'QUESTS_ACTIVE',
  QUESTS_COMPLETED: 'QUESTS_COMPLETED',
  REFERAL: 'REFERAL',
  LEADERBOARD_SQUADS: 'LEADERBOARD_SQUADS',
  LEADERBOARD_SQUAD_CREATE: 'LEADERBOARD_SQUAD_CREATE',
  LEADERBOARD_SQUAD_EDIT: 'LEADERBOARD_SQUAD_EDIT',
  LEADERBOARD_FRIENDS: 'LEADERBOARD_FRIENDS',
};

export const RouterContext = createContext({});

export const RouterProvider = ({ children }) => {
  const [route, setRoute] = useState(ROUTES.EARN);

  const value = useMemo(() => {
    return {
      route,
      setRoute,
    };
  }, [route]);

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

export const useRouterProvider = () => useContext(RouterContext);
