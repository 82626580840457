import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  useFetchAuthTokenMutation,
  useFetchCurrentProfileQuery,
  useStoreTapPointsMutation,
  useFetchReferralsQuery,
  getAuthToken,
  setAuthToken,
  setAuthStatus,
  getAuthStatus,
  AUTH_STATUSES,
} from '../../app/store';
import { getInitialData, expand } from '../helpers/telegram';
import { isNull } from '../helpers/validators';

const calcFarmedPoints = (totalPoints, duration, durationLeft) => {
  if (isNull(totalPoints) || isNull(duration) || isNull(durationLeft)) {
    return 0;
  }
  if (duration <= durationLeft) {
    return totalPoints;
  }
  return Math.round((totalPoints / 100) * (durationLeft / (duration / 100)));
};

export const AppContext = createContext({});

let tapsPeriodStartAt = 0;
let tapsPointsFrozen = 0;
let tapsSentTimer = 0;
let lastTap = null;

export const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [fetchAuthToken] = useFetchAuthTokenMutation();
  const [storeTapPoints] = useStoreTapPointsMutation();

  const authToken = useSelector(getAuthToken);
  const authStatus = useSelector(getAuthStatus);

  const isAuthorized = !!authToken && authStatus === AUTH_STATUSES.AUTHORIZED;

  const extra = { skip: !isAuthorized };
  const { data: profile, isLoading } = useFetchCurrentProfileQuery(null, extra);
  const {
    data: referrals = {
      code: null,
      first_level_count: 0,
      second_level_count: 0,
    },
  } = useFetchReferralsQuery(null, extra);

  const [balancePoints, setBalancePoints] = useState(0);
  const [balancePointsReferrals, setBalancePointsReferrals] = useState(0);
  const [balancePointsTaps, setBalancePointsTaps] = useState(0);
  const [pointsTapped, setPointsTapped] = useState(0);
  const [farmedPoints, setFarmedPoints] = useState(0);
  const [claimAmountPoints, setClaimAmountPoints] = useState(null);
  const [claimDuration, setClaimDuration] = useState(null);
  const [claimDurationLeft, setClaimDurationLeft] = useState(null);
  const [squad, setSquad] = useState(null);
  const [joinedSquad, setJoinedSquad] = useState(null);
  const [squadSocCodeDefault, setSquadSocCodeDefault] = useState(null);
  const [energy, setEnergy] = useState({ current: 0, max: 0 });

  const hasProfile = !!profile;

  useEffect(() => {
    if (profile) {
      setBalancePoints(profile.balance_points);
      setBalancePointsReferrals(profile.balance_points_referrals);
      setBalancePointsTaps(profile.balance_points_taps);
      setClaimAmountPoints(profile.claim_amount_points);
      setClaimDuration(profile.claim_duration);
      setClaimDurationLeft(profile.claim_duration_left);
      setSquad(profile.squad);
      setJoinedSquad(profile.joined_squad);
      setSquadSocCodeDefault(profile.squad_soc_code_default);
      setEnergy({ current: profile.energy, max: profile.energy_max });
      setFarmedPoints(
        calcFarmedPoints(
          profile.claim_amount_points,
          profile.claim_duration,
          profile.claim_duration_left
        )
      );
      let timerClaim = null;
      const timerEnergy = setInterval(() => {
        if (lastTap && lastTap + 1500 > Date.now()) {
          return;
        }
        setEnergy((energy) => {
          const newCurrent = Math.min(energy.current + 1, energy.max);
          return newCurrent === energy.current
            ? energy
            : {
                current: Math.min(energy.current + 1, energy.max),
                max: energy.max,
              };
        });
      }, 1000);
      if (
        profile.claim_duration &&
        profile.claim_duration > profile.claim_duration_left
      ) {
        timerClaim = setInterval(() => {
          setClaimDurationLeft((duration) => duration + 1);
        }, 1000);
      }

      return () => {
        clearInterval(timerClaim);
        clearInterval(timerEnergy);
      };
    }
  }, [profile]);

  useEffect(() => {
    if (!isNull(claimDuration)) {
      setFarmedPoints(
        calcFarmedPoints(claimAmountPoints, claimDuration, claimDurationLeft)
      );
    }
  }, [claimDurationLeft, claimAmountPoints, claimDuration]);

  useEffect(() => {
    if (!authToken && authStatus === AUTH_STATUSES.NOT_INITIALIZED) {
      const initialData = getInitialData() || null;

      if (initialData) {
        dispatch(setAuthStatus(AUTH_STATUSES.FETCHING));
        fetchAuthToken({ init_data: initialData })
          .unwrap()
          .then((resp) => {
            if (resp?.token) {
              dispatch(setAuthStatus(AUTH_STATUSES.AUTHORIZED));
              dispatch(setAuthToken(resp.token));
            } else {
              dispatch(setAuthStatus(AUTH_STATUSES.AUTHORIZE_ERROR));
            }
          })
          .catch((err) => {
            console.log({ err });
            dispatch(setAuthStatus(AUTH_STATUSES.FETCH_ERROR));
          });
      } else {
        dispatch(setAuthStatus(AUTH_STATUSES.NO_INITIAL_DATA));
      }
    }
  }, [authToken, authStatus, fetchAuthToken, dispatch]);

  useEffect(() => {
    expand();
  }, []);

  const value = useMemo(() => {
    const isFarmingStarted =
      !isNull(claimDuration) && !isNull(claimDurationLeft);
    const isFarmingCompleted = isFarmingStarted
      ? claimDuration <= claimDurationLeft
      : null;
    const balancePointsTotal =
      balancePoints + balancePointsReferrals + balancePointsTaps;
    const onTap = () => {
      lastTap = Date.now();
      if (!tapsPeriodStartAt) {
        tapsPeriodStartAt = lastTap;
      }
      setPointsTapped((points) => (tapsPointsFrozen = points + 1));
      setEnergy((energy) => ({
        current: Math.max(energy.current - 1, 0),
        max: energy.max,
      }));
      clearTimeout(tapsSentTimer);
      tapsSentTimer = setTimeout(() => {
        storeTapPoints({
          points: tapsPointsFrozen,
          period_start: tapsPeriodStartAt,
        })
          .unwrap()
          .then((res) => {
            if (res.success) {
              tapsPeriodStartAt = 0;
              setPointsTapped(
                (points) =>
                  (tapsPointsFrozen = Math.max(points - res.points, 0))
              );
              setBalancePoints(res.balance_points);
              setBalancePointsReferrals(res.balance_points_referrals);
              setBalancePointsTaps(res.balance_points_taps);
            }
          });
      }, 1500);
    };

    return {
      balancePoints,
      balancePointsReferrals,
      balancePointsTaps,
      balancePointsTotal,
      farmedPoints,
      pointsTapped,
      claimDuration,
      claimDurationLeft,
      hasProfile,
      profile,
      isFarmingCompleted,
      isFarmingStarted,
      energy,
      squad,
      joinedSquad,
      squadSocCodeDefault,
      isLoading,
      referrals,
      onTap,
    };
  }, [
    balancePoints,
    balancePointsReferrals,
    balancePointsTaps,
    farmedPoints,
    pointsTapped,
    claimDuration,
    claimDurationLeft,
    hasProfile,
    profile,
    energy,
    squad,
    joinedSquad,
    squadSocCodeDefault,
    isLoading,
    referrals,
    storeTapPoints,
  ]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppProvider = () => useContext(AppContext);
