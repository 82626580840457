export const GroupFillIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12.5" cy="9" r="4" fill="currentColor" />
      <circle cx="17.5" cy="9" r="3" fill="currentColor" />
      <circle cx="7.5" cy="9" r="3" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0683 18H20.3949C20.9865 18 21.4402 17.4901 21.2965 16.9162C20.9282 15.4458 19.9478 13 17.4999 13C16.6138 13 15.92 13.3205 15.3779 13.7991C16.8857 14.7773 17.6653 16.4902 18.0683 18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6221 13.7991C9.08001 13.3205 8.38621 13 7.50012 13C5.0522 13 4.07178 15.4458 3.70355 16.9162C3.55983 17.4901 4.01348 18 4.6051 18H6.93167C7.33476 16.4902 8.11435 14.7773 9.6221 13.7991Z"
        fill="currentColor"
      />
      <path
        d="M12.5 14C16.2087 14 17.1665 17.301 17.4139 19.0061C17.4932 19.5526 17.0523 20 16.5 20H8.5C7.94772 20 7.50684 19.5526 7.58614 19.0061C7.83351 17.301 8.79134 14 12.5 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
