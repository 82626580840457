import s from './InputText.module.css';

export const InputText = ({
  className,
  label,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className={`${s.outer} ${className || ''}`}>
      {!!label && <div className={s.label}>{label}</div>}

      <input
        className={s.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
