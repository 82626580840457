import { LinkAltIcon } from '../../../shared/icons/LinkAltIcon';
import { formatScore } from '../../../shared/helpers/formatters';
import { openTelegramLink } from '../../../shared/helpers/telegram';
import { useAppProvider } from '../../../shared/contexts/app-context';
import { BOT_USERNAME } from '../../../shared/config';

import { HowWorksItem } from './HowWorksItem';

import './ReferalView.css';

export const ReferalView = () => {
  const { balancePointsReferrals, referrals } = useAppProvider();

  const linkCode = referrals.code;
  const firstLvlFriends = referrals.first_level_count;
  const secondLvlFriends = referrals.second_level_count;
  const totalFriends = firstLvlFriends + secondLvlFriends;

  return (
    <section className="referal-view">
      <div className="referal-view__share">
        <div className="referal-view__share-info">
          <div className="referal-view__share-info-friends">
            You reffered <span>{totalFriends}</span> friends
          </div>

          <div className="referal-view__share-info-lvl">
            <span>{firstLvlFriends}</span> (1lvl){' '}
            <span>{secondLvlFriends}</span> (2lvl)
          </div>
        </div>

        <div className="referal-view__share-earned">
          <div className="referal-view__share-earned-value">
            {formatScore(balancePointsReferrals)}
          </div>

          <div className="referal-view__share-earned-label">
            Referral coins earned
          </div>
        </div>

        <button
          className="referal-view__share-btn"
          onClick={() => {
            const refLink = `https://t.me/${BOT_USERNAME}?start=${linkCode}`;
            openTelegramLink(
              `https://t.me/share/url?text=${encodeURI(
                'Скорее присоединяйся к игре, чтобы добывать самые ценные ресурсы вместе.\nНаходи драгоценности, обменивай их на токены и получай реальные деньги.'
              )}&url=${encodeURIComponent(refLink)}`
            );
          }}
        >
          Share <LinkAltIcon />
        </button>
      </div>

      <div className="referal-view__heading heading-primary">How it works</div>

      <HowWorksItem num="1" text="Share your referral link" />

      <HowWorksItem
        num="2"
        text={
          <>
            Your frens join Diggy <br />
            and start farming points
          </>
        }
      />

      <HowWorksItem
        num="3"
        text={
          <>
            Earn up to 10% of the <br /> points farmed by friends <br /> and 1%
            from their friends
          </>
        }
      />
    </section>
  );
};
