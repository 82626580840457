import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { RoadFinishFillIcon } from '../../../shared/icons/RoadFinishFillIcon';
import { PercentIcon } from '../../../shared/icons/PercentIcon';
import { GroupFillIcon } from '../../../shared/icons/GroupFillIcon';

import { TabBarItem } from './TabBarItem';

import s from './TabBar.module.css';

export const TabBar = () => {
  const { route, setRoute } = useRouterProvider();
  return (
    <section className={s.outer}>
      <div className={s.inner}>
        <TabBarItem
          icon={GoldDrillIcon}
          label="Earn"
          active={route === ROUTES.EARN}
          onClick={() => setRoute(ROUTES.EARN)}
        />

        <TabBarItem
          icon={RoadFinishFillIcon}
          label="Quests"
          active={
            route === ROUTES.QUESTS_ACTIVE || route === ROUTES.QUESTS_COMPLETED
          }
          onClick={() => setRoute(ROUTES.QUESTS_ACTIVE)}
        />

        <TabBarItem
          icon={PercentIcon}
          label="Referal"
          active={route === ROUTES.REFERAL}
          onClick={() => setRoute(ROUTES.REFERAL)}
        />

        <TabBarItem
          icon={GroupFillIcon}
          label="Leaderboard"
          active={
            route === ROUTES.LEADERBOARD_FRIENDS ||
            route === ROUTES.LEADERBOARD_SQUADS
          }
          onClick={() => setRoute(ROUTES.LEADERBOARD_SQUADS)}
        />
      </div>
    </section>
  );
};
