import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';
import { useAppProvider } from '../../../shared/contexts/app-context';
import { useSquadViewProvider } from '../../../shared/contexts/squad-view-context';
import { BASE_URL } from '../../../shared/config';
import { useFetchMostActiveSquadsQuery } from '../../store';

import { MemberCard } from '../../../widgets/ui/MemberCard';
import { SquadsCreateBanner } from '../../../widgets/ui/SquadsCreateBanner';
import { Heading } from '../../../widgets/ui/Heading';

import s from './LeaderBoardSquadsView.module.css';

export const LeaderBoardSquadsView = () => {
  const { setRoute } = useRouterProvider();
  const { squad, joinedSquad } = useAppProvider();
  const { open } = useSquadViewProvider();
  const { data: squads = { data: [] } } = useFetchMostActiveSquadsQuery();

  const squadsList = squads.data || [];

  return (
    <section className={s.outer}>
      {!joinedSquad && (
        <>
          {squad ? (
            <>
              <Heading>My squad</Heading>

              <MemberCard
                name={squad.name}
                avatar={BASE_URL + squad.file_path}
                variant="large"
                onClick={() => open(squad)}
              />
            </>
          ) : (
            <SquadsCreateBanner
              onCreateSquad={() => setRoute(ROUTES.LEADERBOARD_SQUAD_CREATE)}
            />
          )}
        </>
      )}

      {squadsList.length > 0 && (
        <>
          <Heading className={s.heading}>Most active</Heading>

          {squadsList.map((s, index) => (
            <MemberCard
              key={s.id}
              name={s.name}
              balancePoints={s.balance_points}
              avatar={BASE_URL + s.file_path}
              position={index + 1}
              onClick={() => open(s)}
            />
          ))}
        </>
      )}
    </section>
  );
};
