export const ArrowAltLeftIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6665 22L11.7237 21.0572L10.7809 22L11.7237 22.9428L12.6665 22ZM28.6665 23.3333C29.4029 23.3333 29.9998 22.7364 29.9998 22C29.9998 21.2636 29.4029 20.6667 28.6665 20.6667V23.3333ZM17.057 15.7238L11.7237 21.0572L13.6093 22.9428L18.9426 17.6095L17.057 15.7238ZM11.7237 22.9428L17.057 28.2761L18.9426 26.3905L13.6093 21.0572L11.7237 22.9428ZM12.6665 23.3333H28.6665V20.6667H12.6665V23.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};
