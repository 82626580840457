import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { SquadView } from '../../widgets/ui/SquadView';

export const SquadViewContext = createContext({});

export const SquadViewProvider = ({ children }) => {
  const [squad, setSquad] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback((squad) => {
    setSquad(squad);
    setIsVisible(true);
  }, []);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const value = useMemo(() => {
    return { open, close };
  }, [open, close]);

  return (
    <SquadViewContext.Provider value={value}>
      {children}

      <SquadView squad={squad} visible={isVisible} />
    </SquadViewContext.Provider>
  );
};

export const useSquadViewProvider = () => useContext(SquadViewContext);
