import s from './TabBarItem.module.css';

export const TabBarItem = ({ icon: Icon, label, active, onClick }) => {
  return (
    <div className={`${s.item} ${active ? s.active : ''}`} onClick={onClick}>
      <Icon className={s.icon} />

      <div className={s.label}>{label}</div>
    </div>
  );
};
