import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { formatScore } from '../../../shared/helpers/formatters';

import s from './MemberCard.module.css';

export const MemberCard = ({
  name,
  firstName,
  lastName,
  avatar,
  balancePoints,
  position,
  variant, // 'large' | undefined
  onClick,
}) => {
  const onlyName = balancePoints === undefined && position === undefined;

  return (
    <div
      className={`${s.outer} ${s[`position-${position}`]} ${
        onlyName ? s.onlyName : ''
      } ${variant ? s[`variant-${variant}`] : ''}`}
      onClick={onClick}
    >
      {!!avatar && (
        <div className={s.avatar}>
          <img className={s.avatarImg} src={avatar} alt="" />
        </div>
      )}

      <div className={s.body}>
        <div className={s.name}>
          {(name || `${firstName} ${lastName}`).trim()}
        </div>

        {balancePoints !== undefined && (
          <div className={s.scoreOuter}>
            <div className={s.icon}>
              <GoldDrillIcon />
            </div>

            <div className={s.score}>{formatScore(balancePoints)}</div>
          </div>
        )}
      </div>

      {position !== undefined && <div className={s.number}>{position}</div>}
    </div>
  );
};
