export const ArrowAltRightIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2727 15L22.8661 14.4066L23.4595 15L22.8661 15.5934L22.2727 15ZM7.72725 15.8391C7.26379 15.8391 6.88809 15.4634 6.88809 15C6.88809 14.5365 7.26379 14.1608 7.72725 14.1608V15.8391ZM18.0176 9.55811L22.8661 14.4066L21.6793 15.5934L16.8308 10.7449L18.0176 9.55811ZM22.8661 15.5934L18.0176 20.4418L16.8308 19.2551L21.6793 14.4066L22.8661 15.5934ZM22.2727 15.8391H7.72725V14.1608H22.2727V15.8391Z"
        fill="#DB9F59"
      />
    </svg>
  );
};
