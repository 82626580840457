export const RoadFinishFillIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 17L12.5 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.5 4L12.5 7.42857L12.5 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 3V7C11.5 7.55228 11.9477 8 12.5 8H18.2929C18.7383 8 18.9614 7.46143 18.6464 7.14645L16.6768 5.17678C16.5791 5.07915 16.5791 4.92085 16.6768 4.82322L18.6464 2.85355C18.9614 2.53857 18.7383 2 18.2929 2H12.5C11.9477 2 11.5 2.44772 11.5 3Z"
        fill="currentColor"
      />
      <path
        d="M19.3 22H5.7C4.5799 22 4.01984 22 3.59202 21.782C3.21569 21.5903 2.90973 21.2843 2.71799 20.908C2.5 20.4802 2.5 19.9201 2.5 18.8V13.2C2.5 12.0799 2.5 11.5198 2.71799 11.092C2.90973 10.7157 3.21569 10.4097 3.59202 10.218C4.01984 10 4.5799 10 5.7 10H7.52416C7.85866 10 8.02591 10 8.14306 10.0687C8.24582 10.129 8.32367 10.224 8.36262 10.3365C8.40702 10.4649 8.37422 10.6289 8.30862 10.9569L6.88276 18.0862C6.75155 18.7422 6.68595 19.0702 6.77476 19.3269C6.85265 19.5521 7.00837 19.742 7.21388 19.8626C7.44818 20 7.78268 20 8.45169 20H16.5483C17.2173 20 17.5518 20 17.7861 19.8626C17.9916 19.742 18.1473 19.5521 18.2252 19.3269C18.314 19.0702 18.2484 18.7422 18.1172 18.0862L18.1172 18.0862L16.6914 10.9569C16.6258 10.6289 16.593 10.4649 16.6374 10.3365C16.6763 10.224 16.7542 10.129 16.8569 10.0687C16.9741 10 17.1413 10 17.4758 10H19.3C20.4201 10 20.9802 10 21.408 10.218C21.7843 10.4097 22.0903 10.7157 22.282 11.092C22.5 11.5198 22.5 12.0799 22.5 13.2V18.8C22.5 19.9201 22.5 20.4802 22.282 20.908C22.0903 21.2843 21.7843 21.5903 21.408 21.782C20.9802 22 20.4201 22 19.3 22Z"
        fill="currentColor"
      />
    </svg>
  );
};
