import { useAppProvider } from '../../../shared/contexts/app-context';
import { SquadViewProvider } from '../../../shared/contexts/squad-view-context';

import { Header } from '../../../widgets/ui/Header';
import { TabBar } from '../../../widgets/ui/TabBar';
import { AppLoader } from '../../../widgets/ui/AppLoader';

import s from './AppLayout.module.css';

export const AppLayout = ({ children }) => {
  const { hasProfile } = useAppProvider();

  return (
    <section className={s.outer}>
      <SquadViewProvider>
        {hasProfile ? (
          <>
            <Header />

            <section className={s.inner}>{children}</section>

            <TabBar />
          </>
        ) : (
          <AppLoader />
        )}
      </SquadViewProvider>
    </section>
  );
};
