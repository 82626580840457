import { useEffect } from 'react';

import { ready } from '../../../shared/helpers/telegram';
import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';

import { AppLayout } from '../../../widgets/ui/AppLayout';

import { LeaderBoardFriendsView } from '../LeaderBoardFriendsView';
import { LeaderBoardSquadsView } from '../LeaderBoardSquadsView';
import { LeaderBoardSquadFormView } from '../LeaderBoardSquadFormView';
import { ReferalView } from '../ReferalView';
import { QuestsView } from '../QuestsView';
import { EarnView } from '../EarnView';

export const App = () => {
  const { route } = useRouterProvider();

  useEffect(() => {
    ready();
  }, []);

  return (
    <AppLayout>
      {route === ROUTES.EARN && <EarnView />}

      {route === ROUTES.QUESTS_ACTIVE && <QuestsView />}

      {route === ROUTES.QUESTS_COMPLETED && <QuestsView completed />}

      {route === ROUTES.REFERAL && <ReferalView />}

      {route === ROUTES.LEADERBOARD_SQUADS && <LeaderBoardSquadsView />}

      {route === ROUTES.LEADERBOARD_SQUAD_CREATE && (
        <LeaderBoardSquadFormView />
      )}

      {route === ROUTES.LEADERBOARD_SQUAD_EDIT && (
        <LeaderBoardSquadFormView edit />
      )}

      {route === ROUTES.LEADERBOARD_FRIENDS && <LeaderBoardFriendsView />}
    </AppLayout>
  );
};
