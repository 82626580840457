import s from './SquadsCreateBanner.module.css';

export const SquadsCreateBanner = ({ onCreateSquad }) => {
  return (
    <section className={s.banner}>
      <div className={s.heading}>Join squads</div>
      <div className={s.text}>
        These squads are actively recruiting players. <br />
        Apply if you want to join them!
      </div>
      <div className={s.btn} onClick={onCreateSquad}>
        Create your squad
      </div>
    </section>
  );
};
